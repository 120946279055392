import RecentNews from '../../Elements/RecentNews';
import { Row, Col } from 'reactstrap';
import { Link } from 'gatsby';
import React from "react";

import './index.scss';

const NewsContainer = ({ news, newsCount }: any) => {
  return (
    <Row className="pricipal-container">
      <Col md={8} className="content-list">
        {news && news.map((article: any) => {
          const articleThumbImage = article?.thumb;
          const articleCaption = article?.smallerCaption;
          const articleSmallCaption = article?.smallerCaption;
          const articleUrl = article?.slug;
          const articleCreated = article?.createdAt && new Date(article?.createdAt).toDateString();

          return (
            <article
              className="d-flex justify-content-start align-items-center"
              key={articleUrl}
            >
              <div className="thumb-image-url">
                <Link
                  to={`/${articleUrl}`}
                  className="d-flex"
                >
                  <img src={articleThumbImage} alt="" />
                </Link>
              </div>
              <div className="d-flex flex-column justify-content-start align-items-start p-3 h-100">
                <Link
                  to={`/${articleUrl}`}
                  className="d-flex"
                >
                  <h5 className="text-left article-thumb-small-caption"
                    dangerouslySetInnerHTML={{ __html: articleSmallCaption }}
                  ></h5>
                </Link>
                <div className="d-flex flex-row text-secondary">
                  <small>
                    <i className="fa fa-clock-o"></i>{articleCreated}
                  </small>
                  <small>
                    <i className="fa fa-user"></i>
                    <Link
                      to="/"
                      className="text-secondary"
                    >
                      LaBotana.com
                    </Link>
                  </small>
                  <small>
                    <i className="fa fa-comment-o"></i>
                    <Link
                      to="/"
                      className="text-secondary"
                    >0</Link>
                  </small>
                </div>
                <Link
                  to={`/${articleUrl}`}
                  className="d-flex"
                >
                  <div
                    className="d-flex justify-content-start align-items-start text-left mt-2 article-thumb-caption"
                    dangerouslySetInnerHTML={{ __html: articleCaption }}
                  >
                  </div>
                </Link>
              </div>
            </article>
          )
        })}
      </Col>
      <Col md={4}>
        <RecentNews />
      </Col>
    </Row>
  );
}

export default NewsContainer;
