import React from "react";

export const SEO = ({ children }: any) => (
    <>
        <link rel="icon" href="/labotana-favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="description" content="Web site created using create-react-app" />
        <link rel="manifest" href="/manifest.json" />
        <title>LaBotana : Noticias y chismes de los famosos, desde 1999</title>

        <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
        <meta name="description" content="Noticias de espectáculos y farándula, influencers, realeza, moda, música, cine , televisión, películas, estrenos, chismes, rumores" />

        <meta property="og:locale" content="es_ES" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="LaBotana : Noticias y chismes de los famosos, desde 1999" />
        <meta property="og:description" content="Noticias de espectáculos y farándula, influencers, realeza, moda, música, cine , televisión, películas, estrenos, chismes, rumores" />
        <meta property="og:url" content="https://labotana.com/" />
        <meta property="og:site_name" content="LaBotana : Noticias y chismes de los famosos, desde 1999" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@labotana" />
        <meta name="google-adsense-platform-account" content="ca-host-pub-2644536267352236" />
        <meta name="google-adsense-platform-domain" content="sitekit.withgoogle.com" />
        {children}
    </>
)
