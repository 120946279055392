import { Row, Col } from 'reactstrap';
import React from "react";

import './index.scss';

const SponsorFullBanner = () => {
  return (
    <Row className="sponsor-full-banner">
      <Col md={12}>
        <div
          data-ad-client="ca-pub-4899717035880960"
          data-ad-slot="7111838888"
          data-auto-format="rspv"
          data-full-width=""
          i-amphtml-layout="fixed"
        >
          Sponsor full banner
        </div>
      </Col>
    </Row>
  );
}

export default SponsorFullBanner;
