import SponsorFullBanner from "../components/Sponsor/SponsorFullBanner";
import Container from "../components/Common/Container";
import Footer from "../components/Common/Footer";
import Header from "../components/Common/Header";
import Menu from "../components/Common/Menu";
import { SEO } from '../components/SEO';
import React from "react";

import 'bootstrap-icons/font/bootstrap-icons.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/global.scss';

const IndexPage = ({ pageContext, newsCount }: any) => {
  const news = pageContext.news;

  return (
    <main>
      <Header />
      <Menu />
      <SponsorFullBanner />
      <Container
        news={news}
        newsCount={newsCount}
      />
      <Footer />
    </main>
  );
}

export default IndexPage;

export const Head = () => (
  <SEO>
    <link id="icon" rel="icon" href="icon-specific-for-this-page" />
  </SEO>
);
