import { Row, Col } from 'reactstrap';
import { Link } from 'gatsby';
import React from 'react';

import './index.scss';

const RecentNews = () => {
  return (
    <Row className="white-bg pt-3 pb-3 recent-news-block">
      <Col md={12} className="d-flex flex-column text-left">
        <h6>MÁS RECIENTES</h6>
        <ul>
          <li>
            <Link
              to="/"
            >
              Sherlyn se prepara para tener otro hijo a través de fertilización in vitro
            </Link>
          </li>
          <li>
            <Link
              to="/"
            >
              Famosos que tuvieron suerte en el juego y ganaron la lotería
            </Link>
          </li>
        </ul>
      </Col>
    </Row>
  );
}

export default RecentNews;
